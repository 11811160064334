<template>
  <v-card flat class="pa-3 mt-2">
    <!-- <v-card-text class="d-flex">
      <v-avatar rounded size="120" class="me-6">
        <v-img :src="accountDataLocale.avatarImg"></v-img>
      </v-avatar>

            <div>
        <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="$refs.refInputEl.click()"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Upload new photo</span>
        </v-btn>

        <input
          ref="refInputEl"
          type="file"
          accept=".jpeg,.png,.jpg,GIF"
          :hidden="true"
        />

        <v-btn
          color="error"
          outlined
          class="mt-5"
        >
          Reset
        </v-btn>
        <p class="text-sm mt-5">
          Allowed JPG, GIF or PNG. Max size of 800K
        </p>
      </div>
    </v-card-text> -->

    <v-card-text ref="form">
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col md="4" cols="12">
            <v-text-field v-model="accountDataLocale.usuario" label="Usuário" dense outlined disabled
              :rules="[() => !!accountDataLocale.usuario || 'Campo obrigatório']" :error-messages="errorMessages"
              ref="usuario"></v-text-field>
          </v-col>

          <v-col md="4" cols="12">
            <v-text-field v-model="accountDataLocale.nome" label="Nome" dense outlined
              :rules="[() => !!accountDataLocale.nome || 'Campo obrigatório']" :error-messages="errorMessages"
              ref="nome"></v-text-field>
          </v-col>

          <v-col md="4" cols="12">
            <v-text-field v-model="accountDataLocale.email" label="E-mail" dense outlined
              :rules="[() => !!accountDataLocale.email || 'Campo obrigatório']" :error-messages="errorMessages"
              ref="email"></v-text-field>
          </v-col>


          <!-- alert -->

          <v-col cols="12 justify-center text-center">
            <v-btn color="primary" class="me-3 mt-4" @click="alterarPerfil()">
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-snackbar v-model="snackbar" :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    accountData: {
      type: Object,
      default: () => { },
    },
  },
  setup(props) {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']

    const accountDataLocale = ref(JSON.parse(JSON.stringify(props.accountData)))

    const resetForm = () => {
      accountDataLocale.value = JSON.parse(JSON.stringify(props.accountData))
    }

    return {
      formHasErrors: false,
      errorMessages: '',
      snackbar: false,
      text: '',
      color: 'success',
      status,
      accountDataLocale,
      resetForm,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },

  methods: {
    alterarPerfil: function () {
      this.formHasErrors = false



      if (this.accountDataLocale.usuario != "" && this.accountDataLocale.nome != "" && this.accountDataLocale.email != "") {
        this.$http.post("cadastroUsuario", { usuario: this.accountDataLocale.usuario, nome: this.accountDataLocale.nome, email: this.accountDataLocale.email, id: localStorage.getItem("id"), token: localStorage.getItem("token") })
          .then((res) => {
            if (res.data.status != undefined && res.data.status == 200) {
              localStorage.setItem("nome", res.data.dados.nome)
              localStorage.setItem("email", res.data.dados.email)

              this.text = "Perfil atualizado"
              this.color = "success"
              this.snackbar = true

            } else if (res.data.status != undefined && res.data.status == 500) {
              this.text = res.data.msg
              this.color = "error"
              this.snackbar = true
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }else{
        this.text = "Favor preencher todos campos obrigatórios"
              this.color = "warning"
              this.snackbar = true
      }
    },
  },

  computed: {

  },

  watch: {
    name() {
      this.errorMessages = ''
    },
  },

}
</script>
