<template>
  <v-card flat class="pa-3 mt-2">
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>

          <v-col cols="12" md="12">

            <v-data-table :headers="headers" :items="usuariosMudar" :search="search"  :footer-props="{
              showFirstLastPage: true,
              'items-per-page-text': 'Dados por página'
            }">

              <template v-slot:top>
                <v-dialog v-model="dialog"  persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }" class="text-rigth">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" style="margin-bottom: 20px;">
                      Cadastro
                    </v-btn>

                    <v-card-title>
                      <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
                        hide-details>
                      </v-text-field>
                    </v-card-title>

                  </template>
                  <v-card>
                    <v-toolbar>
                      <v-btn icon @click="fecharModal()">
                        <v-icon>{{ icons.mdiClose }}</v-icon>
                      </v-btn>
                      <v-toolbar-title>Cadastro do usuário</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn text @click="alterarUsuario()" class="primary">
                        <v-icon>{{ icons.mdiContentSave }}</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-list three-line subheader>
                      <v-subheader>Dados obrigatórios</v-subheader>
                      <v-card-text ref="form">
                        <v-form class="multi-col-validation mt-6">
                          <v-row>

                            <v-col class="d-flex" cols="12" sm="4">
                              <v-select v-model="usuario.tipo" :items="tipos" item-value="id" item-text="name"
                                label="Permissão" outlined dense></v-select>
                            </v-col>

                            <v-col md="4" cols="12">
                              <v-text-field v-model="usuario.usuario" label="Usuário" dense outlined
                                :disabled="usuario.id != null" :rules="[() => !!usuario.usuario || 'Campo obrigatório']"
                                :error-messages="errorMessages" ref="usuario"></v-text-field>
                            </v-col>

                            <v-col md="4" cols="12">
                              <v-text-field v-model="usuario.email" label="E-mail" dense outlined
                                :rules="[() => !!usuario.email || 'Campo obrigatório']" :error-messages="errorMessages"
                                ref="email"></v-text-field>
                            </v-col>

                            <v-col md="4" cols="12">
                              <v-text-field v-model="usuario.nome" label="Nome" dense outlined
                                :rules="[() => !!usuario.nome || 'Campo obrigatório']" :error-messages="errorMessages"
                                ref="nome"></v-text-field>
                            </v-col>

                            <v-col md="4" cols="12" v-if="usuario.id == null">
                              <v-text-field v-model="newPassword" :type="isNewPasswordVisible ? 'text' : 'password'"
                                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                                label="Nova senha" outlined dense persistent-hint
                                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                                :rules="[() => !!newPassword || 'Campo obrigatório']" :error-messages="errorMessages">
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>

                    </v-list>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card class="text-center">
                    <v-card-title class="text-h5 text-center">Deseja deletar o usuário ?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  {{ icons.mdiPencil }}
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                  {{ icons.mdiDelete }}
                </v-icon>
              </template>
              <template v-slot:no-data>
                Nenhum usuário encontrado
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-form>

    <v-snackbar v-model="snackbar" :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiLockOpenOutline, mdiInformationOutline, mdiAlphaEBoxOutline, mdiAccountSupervisor, mdiContentSave, mdiDelete, mdiPencil } from '@mdi/js'

export default {

  props: {
    usuarios: {
      type: Array,
      default: () => { },
    },
  },
  setup(props) {
    const isNewPasswordVisible = ref(false)
    var usuariosA = props.usuarios

    return {
      usuariosA,
      isNewPasswordVisible,
      search: "",
      usuario: { id: null, usuario: "", nome: "", tipo: 1, email: "", idEmpresa: 0, senha: "" },
      tipos: [{ id: 1, name: "Administrador" }, { id: 2, name: "Comum" }, { id: 3, name: "Profissional" }],
      dialog: false,
      dialogDelete: false,
      newPassword: '',
      usuariosMudar: [],


      notifications: false,
      sound: true,
      widgets: false,
      icons: {
        mdiClose,
        mdiDelete,
        mdiContentSave,
        mdiPencil
      },
      formHasErrors: false,
      errorMessages: '',
      snackbar: false,
      text: '',
      color: 'success',

      headers: [
        {
          text: 'Usuário',
          align: 'start',
          value: 'usuario',
          sortable: false
        },
        { text: 'Nome', value: 'nome', sortable: false },
        { text: 'E-mail', value: 'email', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      defaultUsuario: { id: null, usuario: "", nome: "", tipo: 1, email: "", idEmpresa: 0, senha: "" },
    }
  },

  methods: {

    pegarDados: function () {
      this.$http.get("usuarios?idEmpresa=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token"))
        .then((res) => {
          if (res.data.status != undefined && res.data.status == 200) {
            var newUsuarios = []
            for (var i = 0; i < res.data.dados.length; i++) {
              if (res.data.dados[i].id != localStorage.getItem("id")) {
                newUsuarios.push(res.data.dados[i])
              }
            }

            this.usuariosMudar = newUsuarios

            this.$forceUpdate();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fecharModal: function () {
      this.formHasErrors = false
      this.usuario = { id: null, usuario: "", nome: "", tipo: 1, email: "", idEmpresa: 0, senha: "" };
      this.dialog = !this.dialog
    },

    alterarUsuario: function () {

      if (this.usuario.usuario != "" && this.usuario.nome != "" && this.usuario.email != "") {
        this.$http.post("cadastroUsuario", {
          usuario: this.usuario.usuario, nome: this.usuario.nome, email: this.usuario.email, id: this.usuario.id,
          tipo: this.usuario.tipo,
          idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token"), senha: this.newPassword
        })
          .then((res) => {
            if (res.data.status != undefined && res.data.status == 200) {
              if (this.usuario.id == null) {
                this.usuariosMudar.push(res.data.dados)
                this.text = "Usuário cadastrado"
                this.color = "success"
                this.snackbar = true
                this.dialog = !this.dialog
              } else {
                Object.assign(this.usuariosMudar[this.editedIndex], this.usuario)

                this.text = "Usuário atualizado"
                this.color = "success"
                this.snackbar = true
                this.dialog = !this.dialog
              }

              this.pegarDados();

            } else if (res.data.status != undefined && res.data.status == 500) {
              this.text = res.data.msg
              this.color = "error"
              this.snackbar = true
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.text = "Favor preencher todos campos obrigatórios"
        this.color = "warning"
        this.snackbar = true
      }
    },

    editItem(item) {
      this.editedIndex = this.usuariosMudar.indexOf(item)
      this.usuario = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.usuariosMudar.indexOf(item)
      this.usuario = Object.assign({}, item)
      this.dialogDelete = true

    },

    deleteItemConfirm() {
      this.usuariosMudar.splice(this.editedIndex, 1)
      this.closeDelete()

      this.$http.post("deletarUsuario", {
        id: this.usuario.id, token: localStorage.getItem("token")
      })
        .then((res) => {
          if (res.data.status != undefined && res.data.status == 200) {
            this.text = "Usuário deletado"
            this.color = "success"
            this.snackbar = true

            this.pegarDados();

          } else if (res.data.status != undefined && res.data.status == 500) {
            this.text = res.data.msg
            this.color = "error"
            this.snackbar = true
          }
        })
        .catch((error) => {
          console.log(error);
        });

    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.usuario = Object.assign({}, this.defaultUsuario)
        this.editedIndex = -1
      })
    },

    closeDelete() {

      this.dialogDelete = false
      this.$nextTick(() => {
        this.usuario = Object.assign({}, this.defaultUsuario)
        this.editedIndex = -1


      })
    },

    save() {

    },
  },

  created() {

  },

  beforeMount() {
    this.pegarDados()
  },

  watch: {
    name() {
      this.errorMessages = ''
    },

    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
}
</script>
