<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <account-settings-account :account-data="accountSettingData.account"></account-settings-account>
      </v-tab-item>

      <v-tab-item>
        <account-settings-security></account-settings-security>
      </v-tab-item>

      <v-tab-item>
        <account-settings-anamnese></account-settings-anamnese>
      </v-tab-item>

      <v-tab-item>
        <account-settings-empresa :account-data="accountSettingData.empresa"></account-settings-empresa>
      </v-tab-item>

      <v-tab-item>
        <account-settings-info :usuarios="accountSettingData.usuarios"></account-settings-info>
      </v-tab-item>



    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiAlphaEBoxOutline, mdiAccountSupervisor, mdiFileChartOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import AccountSettingsAccount from './AccountSettingsAccount.vue'
import AccountSettingsSecurity from './AccountSettingsSecurity.vue'
import AccountSettingsInfo from './AccountSettingsUsuarios.vue'
import AccountSettingsAnamnese from './AccountSettingsAnamnese.vue'
import AccountSettingsEmpresa from './AccountSettingsEmpresa.vue'

export default {
  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountSettingsEmpresa,
    AccountSettingsInfo,
    AccountSettingsAnamnese
  },
  setup() {
    const tab = ref('')

    // tabs
    var tabs = [
      { title: 'Perfil', icon: mdiAccountOutline },
      { title: 'Segurança', icon: mdiLockOpenOutline },
      { title: 'Anamnese', icon: mdiFileChartOutline },
      { title: 'Empresa', icon: mdiAlphaEBoxOutline },
      { title: 'Usuários', icon: mdiAccountSupervisor },
    ]

    // account settings data
    var accountSettingData = {
      account: {
        usuario: localStorage.getItem("usuario"),
        nome: localStorage.getItem("nome"),
        email: localStorage.getItem("email"),
      },

      empresa: {
        empresa: "",
        cnpj: "",
        email: "",
        envioSms: "",
      },

      usuarios: [],

      information: {
        bio: 'The name’s John Deo. I am a tireless seeker of knowledge, occasional purveyor of wisdom and also, coincidentally, a graphic designer. Algolia helps businesses across industries quickly create relevant 😎, scaLabel 😀, and lightning 😍 fast search and discovery experiences.',
        birthday: 'February 22, 1995',
        phone: '954-006-0844',
        website: 'https://themeselection.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
    }

    return {
      tab,
      tabs,
      accountSettingData,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiAlphaEBoxOutline,
        mdiAccountSupervisor,
        mdiFileChartOutline
      },
    }
  },

  methods: {
    pegarDados: function () {
      this.$http.get("empresaId?id=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token"))
        .then((res) => {
          if (res.data.status != undefined && res.data.status == 200) {
            this.accountSettingData.empresa.empresa = res.data.dados.empresa
            this.accountSettingData.empresa.cnpj = res.data.dados.cnpj
            this.accountSettingData.empresa.email = res.data.dados.email
            this.accountSettingData.empresa.envioSms = res.data.dados.envioSms
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.$http.get("usuarios?idEmpresa=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token"))
        .then((res) => {
          if (res.data.status != undefined && res.data.status == 200) {
            this.accountSettingData.usuarios = res.data.dados
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  beforeMount() {
    var logado = localStorage.getItem("login")
    if (logado == undefined) {
      this.$router.push('login')
    } else {
      if (localStorage.getItem("tipo") == 2) {
        this.tabs.splice(3, 2)
      }
      this.pegarDados()
    }
  }
}
</script>
