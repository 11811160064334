<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col md="4" cols="12">
            <v-text-field v-model="accountDataLocale.empresa" label="Nome da empresa" dense outlined
              :rules="[() => !!accountDataLocale.empresa || 'Campo obrigatório']" :error-messages="errorMessages">
            </v-text-field>
          </v-col>

          <v-col md="4" cols="12">
            <v-text-field v-model="accountDataLocale.cnpj" label="CNPJ" dense outlined disabled
              :rules="[() => !!accountDataLocale.cnpj || 'Campo obrigatório']" :error-messages="errorMessages">
            </v-text-field>
          </v-col>

          <v-col md="4" cols="12">
            <v-text-field v-model="accountDataLocale.email" label="E-mail corporativo" dense outlined
              :rules="[() => !!accountDataLocale.email || 'Campo obrigatório']" :error-messages="errorMessages">
            </v-text-field>
          </v-col>

          <v-col md="12" cols="12" style="display: flex; justify-content: center;">
            <v-switch v-model="accountDataLocale.envioSms" :label="'Enviar SMS de confirmação para o paciente ?'"></v-switch>
          </v-col>


          <!-- alert -->

          <v-col cols="12 justify-center text-center">
            <v-btn color="primary" class="me-3 mt-4" @click="alterarEmpresa()">
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-snackbar v-model="snackbar" :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    accountData: {
      type: Object,
      default: () => { },
    },
  },
  setup(props) {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']

    var accountDataLocale = ref(JSON.parse(JSON.stringify(props.accountData)))

    const resetForm = () => {
      accountDataLocale.value = JSON.parse(JSON.stringify(props.accountData))
      console.log(accountDataLocale)
    }

    return {
      status,
      accountDataLocale,
      resetForm,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },

      formHasErrors: false,
      errorMessages: '',
      snackbar: false,
      text: '',
      color: 'success',
    }
  },

  methods: {
    alterarEmpresa: function () {
      this.formHasErrors = false
      if(this.accountDataLocale.envioSms){
        this.accountDataLocale.envioSms = 1
      }else{
        this.accountDataLocale.envioSms = 0
      }

      if (this.accountDataLocale.empresa != "" && this.accountDataLocale.cnpj != "" && this.accountDataLocale.email != "") {
        this.$http.post("editarEmpresa", {envioSms: this.accountDataLocale.envioSms, empresa: this.accountDataLocale.empresa, email: this.accountDataLocale.email, id: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") })
          .then((res) => {
            if (res.data.status != undefined && res.data.status == 200) {
              this.text = "Empresa atualizada"
              this.color = "success"
              this.snackbar = true

            } else if (res.data.status != undefined && res.data.status == 500) {
              this.text = res.data.msg
              this.color = "error"
              this.snackbar = true
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.text = "Favor preencher todos campos obrigatórios"
        this.color = "warning"
        this.snackbar = true
      }
    },
  },

  beforeMount() {
    if(this.accountDataLocale.envioSms == 0){
      this.accountDataLocale.envioSms = false
    }else{
      this.accountDataLocale.envioSms = true
    }
  },
}
</script>
