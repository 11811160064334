<template>
  <v-card flat class="mt-5">
    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="8" md="12">
              <!-- current password -->
              <v-text-field v-model="currentPassword" :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="Senha Atual" outlined dense @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
                class="mt-3" :rules="[() => !!currentPassword || 'Campo obrigatório']" :error-messages="errorMessages">
              </v-text-field>

              <!-- new password -->
              <v-text-field v-model="newPassword" :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" label="Nova senha"
                outlined dense persistent-hint @click:append="isNewPasswordVisible = !isNewPasswordVisible" class="mt-3"
                :rules="[() => !!newPassword || 'Campo obrigatório']" :error-messages="errorMessages">
              </v-text-field>

              <!-- confirm password -->
              <v-text-field v-model="cPassword" :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="Confirmar nova senha" outlined dense class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
                :rules="[() => !!cPassword || 'Campo obrigatório']" :error-messages="errorMessages"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <div class="pa-3">

        <!-- action buttons -->
        <v-card-text class="text-center">
          <v-btn color="primary" class="me-3 mt-3" @click="alterarSenha()">
            Alterar senha
          </v-btn>
        </v-card-text>
      </div>
    </v-form>

    <v-snackbar v-model="snackbar" :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('')
    const newPassword = ref('')
    const cPassword = ref('')

    return {
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,

      formHasErrors: false,
      errorMessages: '',
      snackbar: false,
      text: '',
      color: 'success',

      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },

  methods: {
    alterarSenha: function () {
      this.formHasErrors = false

      if (this.newPassword != this.cPassword) {
        this.text = "Nova senha diferente da confirmação"
        this.color = "error"
        this.snackbar = true
        return
      }

      if (this.newPassword != "" && this.cPassword != "" && this.currentPassword != "") {
        this.$http.post("MudarSenhaUsuario", { senhaAtual: this.currentPassword, novaSenha: '' + this.newPassword, id: localStorage.getItem("id"), token: localStorage.getItem("token") })
          .then((res) => {
            if (res.data.status != undefined && res.data.status == 200) {
              this.text = res.data.msg
              this.color = "success"
              this.snackbar = true

            } else if (res.data.status != undefined && res.data.status == 500) {
              this.text = res.data.msg
              this.color = "error"
              this.snackbar = true
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.text = "Favor preencher todos campos obrigatórios"
        this.color = "warning"
        this.snackbar = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}

.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
