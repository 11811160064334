<template>
  <v-card flat class="pa-3 mt-2">
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>

          <v-col cols="12" md="12">

            <v-data-table :headers="headers" :items="anamneseMudar" :search="search" :footer-props="{
              showFirstLastPage: true,
              'items-per-page-text': 'Dados por página'
            }">

              <template v-slot:top>
                <v-dialog v-model="dialog" persistent max-width="800px">
                  <template v-slot:activator="{ on, attrs }" class="text-rigth">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" style="margin-bottom: 20px;">
                      Cadastro
                    </v-btn>

                    <v-card-title>
                      <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
                        hide-details>
                      </v-text-field>
                    </v-card-title>

                  </template>
                  <v-card>
                    <v-toolbar>
                      <v-btn icon @click="fecharModal()">
                        <v-icon>{{ icons.mdiClose }}</v-icon>
                      </v-btn>
                      <v-toolbar-title>Cadastro da anamnese</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn text @click="alterarAnamnese()" class="primary">
                        <v-icon>{{ icons.mdiContentSave }}</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-list three-line subheader>
                      <v-subheader>Dados obrigatórios</v-subheader>
                      <v-card-text ref="form">
                        <v-form class="multi-col-validation">
                          <v-row>
                            <v-col md="4" cols="12">
                              <v-text-field v-model="anamnese.anamnese" label="Anamnese" dense outlined
                                :rules="[() => !!anamnese.anamnese || 'Campo obrigatório']"
                                :error-messages="errorMessages" ref="anamnese"></v-text-field>
                            </v-col>

                            <v-col md="4" cols="12">
                              <v-text-field v-model="anamnese.qtd" label="Quantidade" dense outlined type="number">
                              </v-text-field>
                            </v-col>

                            <v-col md="4" cols="12">
                              <v-btn color="primary" @click="gerarLista()">Adicionar perguntas</v-btn>
                            </v-col>

                            <v-col>

                              <v-card-text v-for="item in anamnesePerguntas" :key="item.id">
                                <v-row>
                                  <v-col cols="12" md="12">
                                    <v-text-field v-model="item.anamnesePerguntas" label="Pergunta" outlined
                                      type="text"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="10">
                                    <v-radio-group v-model="item.tipo" row outlined dense style="margin-top: -25px;">
                                      <v-radio :label="tipos[0].name" :value="tipos[0].id"></v-radio>
                                      <v-radio :label="tipos[1].name" :value="tipos[1].id"></v-radio>
                                    </v-radio-group>
                                  </v-col>
                                  <v-col cols="12" md="2">
                                    <v-btn icon @click="deletarItem(item)" style="margin-top: -40px;" dense>
                                      <v-icon>{{ icons.mdiDelete }}</v-icon>
                                    </v-btn>
                                  </v-col>

                                </v-row>
                              </v-card-text>
                            </v-col>

                          </v-row>
                        </v-form>
                      </v-card-text>

                    </v-list>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card class="text-center">
                    <v-card-text class="text-h5 text-center">Deseja deletar a anamnese ?</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="warning" outlined @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="success" outlined @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  {{ icons.mdiPencil }}
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                  {{ icons.mdiDelete }}
                </v-icon>
              </template>
              <template v-slot:no-data>
                Nenhum anamnese encontrada
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-form>

    <v-snackbar v-model="snackbar" :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiLockOpenOutline, mdiInformationOutline, mdiAlphaEBoxOutline, mdiAccountSupervisor, mdiContentSave, mdiDelete, mdiPencil } from '@mdi/js'
import ThemeSwitcherVue from '@/layouts/components/ThemeSwitcher.vue'

export default {

  props: {

  },
  setup(props) {
    const isNewPasswordVisible = ref(false)

    return {
      isNewPasswordVisible,
      search: "",
      anamnese: { id: null, anamnese: "", qtd: "", anamnesePerguntas: [] },
      anamnesePerguntas: [],
      tipos: [{ id: 0, name: "Sim/Não" }, { id: 1, name: "Sim/Não/Não sei" }],
      dialog: false,
      dialogDelete: false,
      newPassword: '',
      anamneseMudar: [],


      notifications: false,
      sound: true,
      widgets: false,
      icons: {
        mdiClose,
        mdiDelete,
        mdiContentSave,
        mdiPencil
      },
      formHasErrors: false,
      errorMessages: '',
      snackbar: false,
      text: '',
      color: 'success',

      headers: [
        {
          text: 'Anamnese',
          align: 'start',
          value: 'anamnese',
          sortable: false
        },
        { text: 'Quantidade', value: 'qtd', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      headersPerguntas: [
        {
          text: 'Pergunta',
          align: 'start',
          value: 'anamnesePerguntas',
          sortable: false
        },
        { text: 'Tipo', value: 'tipo', sortable: false },
      ],
      editedIndex: -1,
      defaultAnamnese: { id: null, anamnese: "", qtd: "" },
    }
  },

  methods: {

    pegarDados: function () {
      this.$http.get("anamneses?idEmpresa=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token"))
        .then((res) => {
          if (res.data.status != undefined && res.data.status == 200) {
            this.anamneseMudar = res.data.dados

            this.$forceUpdate();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    gerarLista: function () {
      if (this.anamnese.id == null) {
        this.anamnesePerguntas = []
        for (var i = 0; i < this.anamnese.qtd; i++) {
          console.log(this.anamnesePerguntas)
          this.anamnesePerguntas.push({ anamnesePerguntas: "", tipo: 0 })
        }

        this.$forceUpdate();
      } else {
        for (var i = 0; i < this.anamnese.qtd; i++) {
          console.log(this.anamnesePerguntas)
          this.anamnesePerguntas.push({ anamnesePerguntas: "", tipo: 0 })
        }

        this.$forceUpdate();
      }
    },

    fecharModal: function () {
      this.anamnesePerguntas = []
      this.formHasErrors = false
      this.anamnese = { id: null, anamnese: "", qtd: "", anamnesePerguntas: [] };
      this.dialog = !this.dialog
    },

    alterarAnamnese: function () {

      if (this.anamnese.anamnese != "" && this.anamnese.qtd != "") {

        var anamnesePerguntas = []
        var tipo = []

        for (var i = 0; i < this.anamnesePerguntas.length; i++) {
          if (this.anamnesePerguntas[i].anamnesePerguntas != "") {
            anamnesePerguntas.push(this.anamnesePerguntas[i].anamnesePerguntas)
            tipo.push(parseInt(this.anamnesePerguntas[i].tipo))
          }
        }

        this.anamnese.qtd = anamnesePerguntas.length;

        this.$http.post("cadastroAnamnese", {
          anamnese: this.anamnese.anamnese, qtd: this.anamnese.qtd, anamnesePerguntas: anamnesePerguntas, tipo: tipo, id: this.anamnese.id,
          idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token"), senha: this.newPassword
        })
          .then((res) => {
            if (res.data.status != undefined && res.data.status == 200) {
              if (this.anamnese.id == null) {
                this.anamneseMudar.push(res.data.dados)
                this.text = "Anamnese cadastrada"
                this.color = "success"
                this.snackbar = true
                this.dialog = !this.dialog
              } else {
                Object.assign(this.anamneseMudar[this.editedIndex], this.anamnese)

                this.text = "Anamnese atualizada"
                this.color = "success"
                this.snackbar = true
                this.dialog = !this.dialog
              }

              this.pegarDados();

            } else if (res.data.status != undefined && res.data.status == 500) {
              this.text = res.data.msg
              this.color = "error"
              this.snackbar = true
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.text = "Favor preencher todos campos obrigatórios"
        this.color = "warning"
        this.snackbar = true
      }
    },

    editItem(item) {
      this.editedIndex = this.anamneseMudar.indexOf(item)
      this.anamnese = Object.assign({}, item)

      this.anamnesePerguntas = []
      for (var i = 0; i < this.anamnese.anamnesePerguntas.length; i++) {
        this.anamnesePerguntas.push(this.anamnese.anamnesePerguntas[i])
      }

      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.anamneseMudar.indexOf(item)
      this.anamnese = Object.assign({}, item)
      this.dialogDelete = true

    },

    deletarItem(item) {
      this.anamnesePerguntas.splice(this.anamnesePerguntas.indexOf(item), 1);
      this.$forceUpdate();
    },

    deleteItemConfirm() {
      this.anamneseMudar.splice(this.editedIndex, 1)
      this.closeDelete()

      this.$http.post("deletarAnamnese", {
        id: this.anamnese.id, token: localStorage.getItem("token")
      })
        .then((res) => {
          if (res.data.status != undefined && res.data.status == 200) {
            this.text = "Usuário deletado"
            this.color = "success"
            this.snackbar = true

            this.pegarDados();

          } else if (res.data.status != undefined && res.data.status == 500) {
            this.text = res.data.msg
            this.color = "error"
            this.snackbar = true
          }
        })
        .catch((error) => {
          console.log(error);
        });

    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.anamnese = Object.assign({}, this.defaultAnamnese)
        this.editedIndex = -1
      })
    },

    closeDelete() {

      this.dialogDelete = false
      this.$nextTick(() => {
        this.anamnese = Object.assign({}, this.defaultAnamnese)
        this.editedIndex = -1


      })
    },
  },

  created() {

  },

  beforeMount() {
    this.pegarDados()
  },

  watch: {
    name() {
      this.errorMessages = ''
    },

    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
}
</script>
